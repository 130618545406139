.account-title-text {
    font-size: 22px;
    font-weight: 500;
}
.signin-title-text {
    font-size: 22px;
    font-weight: 500;
    /*padding-left: 62px;*/
    /*padding-right: 62px;*/
    white-space: nowrap;
}
.account-check-text {
    font-size: 13px;
    color: #10BED2;
}
.account-check-link {
    font-size: 13px;
    font-weight: bold;
    color: #10BED2;
    /*text-decoration:none;*/
}
.signup-terms-text {
    font-size: 11px;
    color: #516F90;
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
}
.signup-terms-link {
    font-size: 11px;
    font-weight: bold;
    color: #10BED2;
    text-decoration:none;
}
.reset-goback-text {
    font-size: 12px;
    color: #516F90;
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
}
.reset-login-link {
    font-size: 12px;
    font-weight: bold;
    color: #10BED2;
    text-decoration:none;
}
.no-receive-code{
    color: #10BED2;
}
.button-left-text {
    font-size: 12px;
    color: #516F90;
}
.resend-spinner {
    padding-top: 100px;
    padding-left: 5px;
}
